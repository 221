@import "layout/layout";
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";


html,
body {
  margin: 0;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

